import * as React from "react";
import { useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import { green } from "@material-ui/core/colors";
import { useQuery, useMutation } from "@apollo/client";

import {
  ButtonCustomize,
  Input,
  Modal,
  LoadingBrief,
  LoadingChapters,
} from "../../commons";
import { UploadFile } from "..";

import {
  GET_LIST_PRODUCT_SPECS,
  CREATE_PRODUCT_SPEC,
  UPDATE_PRODUCT_SPEC,
  DELETE_PRODUCT_SPEC,
} from "../../api";
import {
  GetListProductSpecsQuery,
  GetListProductSpecsQueryVariables,
  CreateProductSpecMutation,
  CreateProductSpecMutationVariables,
  UpdateProductSpecMutation,
  UpdateProductSpecMutationVariables,
  DeleteProductSpecMutation,
  DeleteProductSpecMutationVariables,
  KeyValueInput,
  CreateProductSpecInput,
  ProductSpecPayload,
} from "../../api/types";
import { snackBarVar } from "../../api/local-state";

import drag from "../../img/digishop/menu-icons/drag.svg";

import { Container } from "./AudioContentForm--styles";

const AudioContentForm = (): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const { id } = useParams<{ id?: undefined }>();

  const [listSpecifications, setlistSpecifications] = React.useState<
    CreateProductSpecInput[]
  >([]);
  const [title, setTitle] = React.useState("");
  const [chaptersKind, setChaptersKind] = React.useState("");
  const [chaptersUrl, setChaptersUrl] = React.useState("");
  const [duration, setDuration] = React.useState<KeyValueInput | undefined>();
  const [indexEdit, setIndexEdit] = React.useState<number | undefined>(
    undefined
  );
  const [open, setOpen] = React.useState(false);
  const [idChp, setIdChp] = React.useState<string | undefined | null>(
    undefined
  );
  const [loadingUpload, setloadingUpload] = React.useState(false);
  const [identifier, setIdentifier] = React.useState<"BRIEF" | "CHAPTERS">(
    "BRIEF"
  );

  const handleListSpecification = () => {
    setlistSpecifications((prevList) => {
      const arrayList = [...prevList];
      arrayList.push({
        token,
        identifier: "CHAPTERS",
        product: id || "",
        title: "",
        specifications: [],
      });
      return arrayList;
    });
  };

  const {
    data: dataBrief,
    loading: loadingBrief,
    refetch,
  } = useQuery<GetListProductSpecsQuery, GetListProductSpecsQueryVariables>(
    GET_LIST_PRODUCT_SPECS,
    {
      variables: {
        input: {
          token,
          product: id || "",
          identifier: "BRIEF",
        },
      },
      notifyOnNetworkStatusChange: true,
      skip: !id,
    }
  );

  const {
    data: dataChapters,
    loading: loadingChapters,
    refetch: refetchDataChapters,
  } = useQuery<GetListProductSpecsQuery, GetListProductSpecsQueryVariables>(
    GET_LIST_PRODUCT_SPECS,
    {
      variables: {
        input: {
          token,
          product: id || "",
          identifier: "CHAPTERS",
        },
      },
      notifyOnNetworkStatusChange: true,
      skip: !id,
    }
  );

  const listChapters = dataChapters?.getListProductSpecs?.list || [];

  const [createProductSpec, { loading: loadingCreate }] = useMutation<
    CreateProductSpecMutation,
    CreateProductSpecMutationVariables
  >(CREATE_PRODUCT_SPEC, {
    onCompleted: () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      identifier === "BRIEF" ? refetch() : refetchDataChapters();
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successfully",
      });
      setlistSpecifications([]);
      setTitle("");
      setDuration(undefined);
      setChaptersKind("");
      setChaptersUrl("");
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
  });

  const [updateProductSpec, { loading: loadingUpdate }] = useMutation<
    UpdateProductSpecMutation,
    UpdateProductSpecMutationVariables
  >(UPDATE_PRODUCT_SPEC, {
    onCompleted: () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      identifier === "BRIEF" ? refetch() : refetchDataChapters();
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successfully",
      });
      setIndexEdit(undefined);
      setlistSpecifications([]);
      setDuration(undefined);
      setTitle("");
      setChaptersUrl("");
      setChaptersKind("");
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
  });

  const [deleteProductSpec, { loading: loadingDelete }] = useMutation<
    DeleteProductSpecMutation,
    DeleteProductSpecMutationVariables
  >(DELETE_PRODUCT_SPEC, {
    onCompleted: () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      identifier === "BRIEF" ? refetch() : refetchDataChapters();
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successfully",
      });
      setOpen(false);
      setIdChp(undefined);
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
  });

  const handleEnabledUpdate = (values: ProductSpecPayload) => {
    setTitle(values.title || "");
    setDuration({
      key: "duration",
      value: values.specifications?.find((o) => o.key === "duration")?.value,
    });
    setChaptersKind(
      values.specifications?.find((o) => o.key !== "duration")?.key || ""
    );
    setChaptersUrl(
      values.specifications?.find((o) => o.key !== "duration")?.value || ""
    );
  };

  const handleCreateBrief = (kind: string, url: string) => {
    if (id) {
      const specifications: KeyValueInput[] = [];
      specifications.push({
        key: kind,
        value: url,
      });
      setIdentifier("BRIEF");
      createProductSpec({
        variables: {
          input: {
            token,
            product: id || "",
            identifier: "BRIEF",
            title: "Brief sample",
            specifications,
          },
        },
      });
    }
  };

  const handleUpdateBrief = (kind: string, url: string) => {
    if (dataBrief?.getListProductSpecs?.list?.[0]?.id) {
      const specifications: KeyValueInput[] = [];
      specifications.push({
        key: kind,
        value: url,
      });
      setIdentifier("BRIEF");
      updateProductSpec({
        variables: {
          input: {
            token,
            id: dataBrief.getListProductSpecs.list[0].id,
            specifications,
          },
        },
      });
    }
  };

  const handleCreateChapters = () => {
    if (id) {
      const specifications: KeyValueInput[] = [];
      specifications.push({
        key: chaptersKind,
        value: chaptersUrl,
      });
      specifications.push({
        key: duration?.key,
        value: duration?.value,
      });
      setIdentifier("CHAPTERS");
      createProductSpec({
        variables: {
          input: {
            token,
            product: id || "",
            identifier: "CHAPTERS",
            title,
            specifications,
          },
        },
      });
    }
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]*$/;
    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.charCode;
    if (keyCode === 8) {
      setDuration((prevDuration) => {
        const dataDuration = { ...prevDuration };
        dataDuration.value = dataDuration.value?.substring(
          0,
          dataDuration.value?.length - 1
        );
        return dataDuration;
      });
      event.preventDefault();
    }
  };

  const handleDuration = (event: React.ChangeEvent<HTMLInputElement>) => {
    const long =
      event?.target?.value?.length > 2 || event?.target?.value?.length > 5
        ? event?.target?.value?.length - 1
        : event?.target?.value?.length;
    if (long < 8) {
      if (long < 5 && long % 2 === 0) {
        setDuration({
          key: "duration",
          value: event.target.value.concat(":"),
        });
      } else {
        setDuration({
          key: "duration",
          value: event.target.value,
        });
      }
    }
  };

  const handleUpdateChapters = (
    idChapter: string,
    titleChapter: string,
    kind: string,
    url: string,
    durationSpec?: string | null
  ) => {
    const specifications: KeyValueInput[] = [];
    specifications.push({
      key: kind,
      value: url,
    });
    if (durationSpec) {
      specifications.push({
        key: "duration",
        value: durationSpec,
      });
    }
    setIdentifier("CHAPTERS");
    updateProductSpec({
      variables: {
        input: {
          token,
          id: idChapter,
          title: titleChapter,
          specifications,
        },
      },
    });
  };

  const handleClear = () => {
    setTitle("");
    setChaptersKind("");
    setChaptersUrl("");
    setDuration(undefined);
  };

  return (
    <Container>
      {/* brief */}

      <Typography
        variant="h2"
        className="title-content"
        style={{ marginBottom: 8 }}
      >
        Brief
      </Typography>
      {loadingBrief ? (
        <LoadingBrief />
      ) : (
        <>
          <div className="row">
            <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
              Chapter Title
            </Typography>
            <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
              File
            </Typography>
            <div />
          </div>
          <div className="row">
            <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
              Brief sample
            </Typography>
            <UploadFile
              uploadTo="AUDIO_FILE"
              onLoaded={(kind, url) => handleCreateBrief(kind, url)}
              fileName={
                dataBrief?.getListProductSpecs?.list?.find(
                  (brief) => brief.identifier === "BRIEF"
                )?.specifications?.[0]?.key || ""
              }
              onUpdate={(kind, url) => handleUpdateBrief(kind, url)}
            />
            <div>
              {dataBrief?.getListProductSpecs?.list[0]?.id && (
                <IconButton
                  onClick={() => setOpen(true)}
                  disabled={loadingDelete}
                  size="small"
                  style={{ color: "#f22929", width: 30 }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          </div>
        </>
      )}

      {/* Chapters */}
      <Typography
        variant="h2"
        className="title-content"
        style={{ margin: "16px 0" }}
      >
        Chapters
      </Typography>
      {loadingChapters ? (
        <LoadingChapters />
      ) : (
        <>
          <div className="row-table chapters">
            <Typography />
            <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
              Chapter title
            </Typography>
            <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
              Duration
            </Typography>
            <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
              File
            </Typography>
            <Typography />
          </div>
          {listChapters?.map((item, index) => (
            <div className="row-table chapters drag" key={item.id}>
              <img src={drag} alt="drag" />
              {indexEdit === index ? (
                <Input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              ) : (
                <Typography variant="subtitle2">{item.title}</Typography>
              )}
              {indexEdit === index && index === 0 ? (
                <div style={{ margin: "0 15px" }}>
                  <Input
                    value={duration?.value}
                    onChange={handleDuration}
                    onKeyPress={onKeyPress}
                    onKeyDown={onKeyDown}
                    placeholder="hh:mm:ss"
                  />
                </div>
              ) : (
                <Typography variant="subtitle2">
                  {
                    item.specifications?.find((o) => o.key === "duration")
                      ?.value
                  }
                </Typography>
              )}
              {indexEdit === index ? (
                <UploadFile
                  uploadTo="AUDIO_FILE"
                  fileName={
                    item.specifications?.find((o) => o.key !== "duration")
                      ?.key || ""
                  }
                  onUpdate={(kind, url) => {
                    setChaptersKind(kind);
                    setChaptersUrl(url);
                  }}
                  onLoaded={(kind, url) => {
                    setChaptersKind(kind);
                    setChaptersUrl(url);
                  }}
                  onLoading={(ldupload) => setloadingUpload(ldupload)}
                />
              ) : (
                <Typography variant="subtitle2" className="file-url-upload">
                  {item.specifications?.find((o) => o.key !== "duration")?.key}
                </Typography>
              )}
              {!(indexEdit === index) ? (
                <IconButton
                  size="small"
                  onClick={() => {
                    handleEnabledUpdate(item);
                    setIndexEdit(index);
                  }}
                  className="btn-edit"
                  disabled={listSpecifications.length > 0 || loadingDelete}
                >
                  <EditIcon />
                </IconButton>
              ) : (
                <Box sx={{ position: "relative" }}>
                  <IconButton
                    size="small"
                    onClick={() =>
                      item?.id &&
                      item?.title &&
                      handleUpdateChapters(
                        item.id,
                        title,
                        chaptersKind,
                        chaptersUrl,
                        duration?.value
                      )
                    }
                    style={{ width: 30 }}
                    disabled={loadingUpdate || loadingUpload}
                  >
                    <SaveIcon />
                  </IconButton>

                  {loadingUpdate && (
                    <CircularProgress
                      size={32}
                      style={{
                        color: green[500],
                        position: "absolute",
                        top: 0,
                        left: -1,
                        zIndex: 9999,
                      }}
                    />
                  )}
                </Box>
              )}
              {!(indexEdit === index) ? (
                <Box sx={{ position: "relative" }}>
                  <IconButton
                    onClick={() => {
                      setIdentifier("CHAPTERS");
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      item?.id &&
                        deleteProductSpec({
                          variables: {
                            input: {
                              token,
                              id: item.id,
                            },
                          },
                        });
                      setTitle("");
                      setDuration(undefined);
                      setIdChp(item?.id);
                    }}
                    disabled={loadingDelete}
                    size="small"
                    className="btn-delete"
                  >
                    <DeleteIcon />
                  </IconButton>

                  {loadingDelete && idChp === item.id && (
                    <CircularProgress
                      size={32}
                      style={{
                        color: green[500],
                        position: "absolute",
                        top: 0,
                        left: -1,
                        zIndex: 9999,
                      }}
                    />
                  )}
                </Box>
              ) : (
                <IconButton
                  size="small"
                  onClick={() => {
                    setIndexEdit(undefined);
                    handleClear();
                  }}
                  style={{ color: "#f22929", width: 30 }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </div>
          ))}
          {listSpecifications?.map((item) => (
            <div className="row-table chapters drag" key={item.identifier}>
              <img src={drag} alt="drag" />
              <div style={{ marginRight: 15 }}>
                <Input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div style={{ marginRight: 15 }}>
                {listChapters?.length === 0 && (
                  <Input
                    value={duration?.value}
                    onChange={handleDuration}
                    onKeyPress={onKeyPress}
                    onKeyDown={onKeyDown}
                    placeholder="hh:mm:ss"
                  />
                )}
              </div>
              <UploadFile
                uploadTo="AUDIO_FILE"
                onLoaded={(kind, url) => {
                  setChaptersKind(kind);
                  setChaptersUrl(url);
                }}
                onLoading={(ldupload) => setloadingUpload(ldupload)}
              />
              <Box sx={{ position: "relative" }}>
                <IconButton
                  onClick={handleCreateChapters}
                  size="small"
                  disabled={!title || loadingUpload}
                >
                  <SaveIcon />
                </IconButton>

                {loadingCreate && (
                  <CircularProgress
                    size={32}
                    style={{
                      color: green[500],
                      position: "absolute",
                      top: 0,
                      left: -1,
                      zIndex: 9999,
                    }}
                  />
                )}
              </Box>

              <IconButton
                onClick={() => {
                  setlistSpecifications([]);
                  setDuration(undefined);
                  setTitle("");
                }}
                size="small"
                style={{ color: "#f22929", width: 30 }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          ))}
        </>
      )}
      {!loadingChapters &&
        listChapters.length === 0 &&
        listSpecifications.length === 0 && (
          <Typography variant="subtitle2" className="list-empty">
            No data available
          </Typography>
        )}
      <div className="row-add-btn">
        <ButtonCustomize
          onClick={handleListSpecification}
          disabled={listSpecifications.length > 0 || indexEdit !== undefined}
        >
          <AddIcon /> &nbsp; Add chapter
        </ButtonCustomize>
      </div>
      <Modal
        open={open}
        title="Delete Brief ?"
        message="Are you sure you want to remove this brief ?"
        handleClose={() => setOpen(false)}
        handleContent={() => {
          setIdentifier("BRIEF");
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          dataBrief?.getListProductSpecs?.list[0]?.id &&
            deleteProductSpec({
              variables: {
                input: {
                  token,
                  id: dataBrief.getListProductSpecs.list[0].id,
                },
              },
            });
        }}
        action="delete"
        loading={loadingDelete}
      />
    </Container>
  );
};

export default AudioContentForm;
