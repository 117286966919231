import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

export interface DialogProps {
  open: boolean;
  title: string;
  children?: React.ReactNode;
  handleClose: () => void;
  handleContent: () => void;
  message?: string;
  action: "delete" | "add" | "update" | "notice" | "detail";
  loading?: boolean;
  disabled?: boolean;
}

const Modal: React.FC<DialogProps> = (props) => {
  const { open, title, action, message, children, loading, disabled } = props;
  const intl = useIntl();

  const [Open, setOpen] = React.useState<boolean>(false);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };

  const handleModalContent = () => {
    props.handleContent();
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={Open}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <Wrapper className="modal-content">
        {children}
        <span className="modal-message">{message}</span>
        <div className="modal-action-btns">
          {action === "delete" ? (
            <Button
              data-testid="modal-delete-action"
              variant="contained"
              disableElevation
              onClick={handleModalContent}
              style={{
                position: "relative",
                backgroundColor: loading ? "rgba(0, 0, 0, 0.12)" : "#F67070",
                color: loading ? "rgba(0, 0, 0, 0.26)" : "#ffffff",
              }}
              disabled={loading}
            >
              {intl.formatMessage({ id: "Modal.delete" })}
              {loading && (
                <CircularProgress size={24} style={{ position: "absolute" }} />
              )}
            </Button>
          ) : (
            action !== "notice" &&
            action !== "detail" && (
              <Button
                type="button"
                data-testid="modal-confirm-action"
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleModalContent}
                disabled={loading || disabled}
                // disabled
                style={{ position: "relative" }}
              >
                {action === "add"
                  ? intl.formatMessage({ id: "Modal.add" })
                  : intl.formatMessage({ id: "Modal.update" })}
                {loading && (
                  <CircularProgress
                    size={24}
                    style={{ position: "absolute" }}
                  />
                )}
              </Button>
            )
          )}
          <Button
            data-testid="modal-cancel-action"
            variant="contained"
            color={
              action === "notice" || action === "detail" ? "primary" : "default"
            }
            disableElevation
            onClick={handleClose}
          >
            {action === "notice"
              ? intl.formatMessage({ id: "Modal.notice" })
              : action === "detail"
                ? intl.formatMessage({ id: "Modal.close" })
                : intl.formatMessage({ id: "Modal.cancel" })}
          </Button>
        </div>
      </Wrapper>
    </Dialog>
  );
};

const Wrapper = styled.div`
  .MuiFilledInput-root {
    border-radius: 5px;
    border: 2px solid #f1f2fb;
    background-color: #ffffff;
    ::before {
      content: none;
    }
    ::after {
      content: none;
    }
  }
  .modal-message {
    color: #757575;
    margin-bottom: 20px;
    display: block;
  }
  padding: 0 24px;
  .modal-action-btns {
    padding: 0px 0px 24px;
    min-width: 380px;
    display: flex;
    flex-direction: row-reverse;
    button:first-child {
      margin-left: 24px;
    }
  }
`;

export default Modal;
