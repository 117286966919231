import gql from "graphql-tag";

export const GET_MENU_TYPE_PRODUCTS_BOOKS = gql`
  query GetMenuTypeProducts($input: GetMenuTypeProductsInput!) {
    getMenuTypeProducts(input: $input) {
      total
      list {
        id
        identifier
        picture {
          id
          fileUrl
        }
        gallery {
          id
          fileUrl
        }
        name {
          id
          languageCode
          value
        }
        description {
          id
          languageCode
          value
        }
        attributes {
          id
          identifier
          name {
            id
            languageCode
            value
          }
        }
        customFields {
          key
          value
        }
        category {
          id
          name {
            id
            languageCode
            value
          }
        }
        categories {
          id
          name {
            id
            languageCode
            value
          }
        }
        price {
          value
          currency {
            id
            code
          }
        }
        tags
        createdAt
        relatedTo
        isPublished
        isFree
        kind
      }
    }
  }
`;

// GetMenuTypeProductDetails

export const GET_MENU_TYPE_PRODUCT_DETAILS = gql`
  query GetMenuTypeProductDetails($input: GetMenuTypeProductDetailsInput!) {
    getMenuTypeProductDetails(input: $input) {
      id
      category {
        id
        name {
          id
          languageCode
          value
        }
      }
      categories {
        id
      }
      identifier
      name {
        id
        languageCode
        value
      }
      description {
        id
        languageCode
        value
      }
      kind
      tags
      picture {
        id
        fileUrl
      }
      gallery {
        id
        fileUrl
      }
      price {
        value
        currency {
          id
          code
        }
      }
      attributes {
        id
        identifier
        name {
          id
          languageCode
          value
        }
      }
      isPublished
      isFree
    }
  }
`;

export const CREATE_MENU_TYPE_PRODUCT = gql`
  mutation CreateMenuTypeProduct($input: CreateMenuTypeProductInput!) {
    createMenuTypeProduct(input: $input) {
      id
    }
  }
`;

export const UPDATE_MENU_TYPE_PRODUCT = gql`
  mutation UpdateMenuTypeProduct($input: UpdateMenuTypeProductInput!) {
    updateMenuTypeProduct(input: $input) {
      success
    }
  }
`;

export const GET_LIST_PRODUCT_ATTRIBUTES = gql`
  query GetListProductAttributes($input: GetListProductAttributesInput!) {
    getListProductAttributes(input: $input) {
      list {
        id
        name {
          id
          languageCode
          value
        }
      }
    }
  }
`;

export const CREATE_PRODUCT_ATTRIBUTE = gql`
  mutation CreateProductAttribute($input: CreateProductAttributeInput!) {
    createProductAttribute(input: $input) {
      id
    }
  }
`;

export const DELETE_PRODUCT_ATTRIBUTE = gql`
  mutation DeleteProductAttribute($input: DeleteProductAttributeInput!) {
    deleteProductAttribute(input: $input) {
      success
    }
  }
`;

export const UPDATE_PRODUCT_ATTRIBUTE = gql`
  mutation UpdateProductAttribute($input: UpdateProductAttributeInput!) {
    updateProductAttribute(input: $input) {
      success
    }
  }
`;

export const GET_LIST_PRODUCT_SPECS = gql`
  query GetListProductSpecs($input: GetListProductSpecsInput!) {
    getListProductSpecs(input: $input) {
      list {
        id
        identifier
        title
        specifications {
          key
          value
        }
      }
    }
  }
`;

export const CREATE_PRODUCT_SPEC = gql`
  mutation createProductSpec($input: CreateProductSpecInput!) {
    createProductSpec(input: $input) {
      id
    }
  }
`;

export const UPDATE_PRODUCT_SPEC = gql`
  mutation updateProductSpec($input: UpdateProductSpecInput!) {
    updateProductSpec(input: $input) {
      success
    }
  }
`;

export const DELETE_PRODUCT_SPEC = gql`
  mutation deleteProductSpec($input: DeleteProductSpecInput!) {
    deleteProductSpec(input: $input) {
      success
    }
  }
`;

export const GET_DTONE_LIST_SERVICES = gql`
  query getDtoneListServices($input: GetDTOneListServicesInput!) {
    getDtoneListServices(input: $input) {
      list {
        id
        name
      }
    }
  }
`;

export const GET_DTONE_LIST_PRODUCTS = gql`
  query GetDtoneListProducts($input: GetDTOneListProductsInput!) {
    getDtoneListProducts(input: $input) {
      list {
        id
        name
        operatorName
      }
    }
  }
`;

export const GET_MENU_TYPE_PRODUCTS_BOOKS_STORIES = gql`
  query GetMenuTypeProductsStories($input: GetMenuTypeProductsInput!) {
    getMenuTypeProducts(input: $input) {
      total
      list {
        id
        identifier
        name {
          id
          languageCode
          value
        }
      }
    }
  }
`;
