import * as React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { useQuery, useReactiveVar } from "@apollo/client";

import { BreadCrumbsTitle, ButtonCustomize, Modal } from "../../../commons";
import Table, { ColumnsProps } from "../../../commons/Table";

import { HOST_FORSA, HOST_RAWI } from "../../../api/config";

import { Wrapper, Note, CustomerIconButton } from "./Plans-style";
import {
  GetListMenuPacksQuery,
  GetListMenuPacksQueryVariables,
  MenuPayload,
} from "../../../api/types";
import { SelectedMenuVar } from "../../../api/local-state";
import { GET_PACKS } from "../../../api/packs.api";

const { host } = window.location;

const Plans = (): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const [open, setOpen] = React.useState(false);
  const [packId, setpackId] = React.useState("");

  console.log("#localMenu?.id", localMenu?.id);

  const { data: listPacks } = useQuery<
    GetListMenuPacksQuery,
    GetListMenuPacksQueryVariables
  >(GET_PACKS, {
    variables: { input: { token, menu: localMenu?.id || "" } },
    skip: !localMenu?.id,
    fetchPolicy: "network-only",
  });

  console.log("#listPacks", listPacks);

  const columns: ColumnsProps = [
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Description",
      accessor: "description",
    },
    {
      header: "Price",
      accessor: "price",
    },
    {
      header: "Payment Frequency",
      accessor: "paymentFrequency",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "80px" },
    },
  ];

  const renderTableRows = () => {
    return (
      listPacks?.getListMenuPacks.packs?.map((item) => ({
        name: (
          <Typography variant="subtitle2" style={{ margin: "5px 0" }}>
            {item.name.defaultContent}
          </Typography>
        ),
        description: (
          <Typography variant="subtitle2" style={{ margin: "5px 0" }}>
            {item.description.defaultContent}
          </Typography>
        ),
        price: (
          <Typography variant="subtitle2" style={{ margin: "5px 0" }}>
            {item.price?.[0].price}&nbsp;{item.price?.[0].currency}
          </Typography>
        ),

        paymentFrequency: (
          <Typography variant="subtitle2" style={{ margin: "5px 0" }}>
            {item.durationPack}
          </Typography>
        ),
        actions: (
          <CustomerIconButton
            onClick={() => {
              // eslint-disable-next-line no-underscore-dangle
              setpackId(item.Id);
              setOpen(true);
            }}
          >
            <DeleteIcon />
          </CustomerIconButton>
        ),
      })) || []
    );
  };

  return (
    <Wrapper>
      <Note>
        <BreadCrumbsTitle
          labelIntl={["Store settings", "Plans"]}
          iconAlt="products"
        />
        <Link to="/plans-form">
          <ButtonCustomize
            variant="contained"
            color="secondary"
            style={{ marginLeft: 10 }}
          >
            <AddIcon />
            &nbsp; Add new
          </ButtonCustomize>
        </Link>
      </Note>
      <div className="container">
        <Table
          columns={columns}
          data={renderTableRows()}
          emptyMessage="No data available"
        />
      </div>
      <Modal
        open={open}
        title="Delete plan"
        message="Are you sure you want to remove this plan ?"
        handleClose={() => setOpen(false)}
        handleContent={() => {}}
        action="delete"
      />
    </Wrapper>
  );
};

export default Plans;
