import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { useQuery, useMutation, useReactiveVar } from "@apollo/client";

import {
  BreadCrumbsTitle,
  ButtonCustomize,
  Input,
  Select,
} from "../../../commons";

import { GETLISTCURRENCIES } from "../../../api";
import {
  CreatePackMutation,
  CreatePackMutationVariables,
  GetListCurrenciesQuery,
  GetListCurrenciesQueryVariables,
  MenuPayload,
  CreatePackInput,
} from "../../../api/types";
import { HOST_FORSA, HOST_RAWI } from "../../../api/config";

import { Wrapper, Note } from "./Plans-style";
import { SelectedMenuVar } from "../../../api/local-state";
import { CREATE_PACK, GET_PACKS } from "../../../api/packs.api";

const { host } = window.location;

const PlansForm = (): JSX.Element => {
  const histroy = useHistory();
  const token = localStorage.getItem("token") || "";
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const createPackInit: CreatePackInput = {
    name: { defaultContent: "", defaultLang: "AR" },
    description: { defaultContent: "", defaultLang: "AR" },
    menuId: localMenu?.id || "",
    displayOrder: 0,
    price: [{ currency: "", price: 0 }],
    token,
    durationPack: 1,
  };

  const [state, setState] = React.useState<CreatePackInput>(createPackInit);
  const [isExistLimit, setisExistLimit] = React.useState<boolean>(false);

  const langIsMain =
    localMenu?.languages?.find((o) => o.isMain)?.language?.code || "AR";

  const [createPack] = useMutation<
    CreatePackMutation,
    CreatePackMutationVariables
  >(CREATE_PACK, {
    refetchQueries: () => [
      {
        query: GET_PACKS,
        variables: { input: { token, menu: localMenu?.id || "" } },
      },
    ],
    onCompleted: () => {
      histroy.goBack();
    },
  });

  const { data: listCurencies } = useQuery<
    GetListCurrenciesQuery,
    GetListCurrenciesQueryVariables
  >(GETLISTCURRENCIES);

  const onKeyPressForFloat = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^\d*\.?\d*$/;
    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  const handleSave = () => {
    createPack({ variables: { input: state } });
  };
  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setState({
      ...state,
      name: { ...state.name, defaultContent: value, defaultLang: langIsMain },
    });
  };
  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setState({
      ...state,
      price: [{ ...state.price[0], price: Number(value) }],
    });
  };
  const handleChangeCurrency = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const { value } = event.target;
    setState({
      ...state,
      price: [{ ...state.price[0], currency: String(value) }],
    });
  };

  const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (Number(value) < 1) return;
    setState({
      ...state,
      durationPack: Number(value),
    });
  };
  const handleChangeProductLimit = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    if (Number(value) < 1) return;
    setState({
      ...state,
      productLimit: Number(value),
    });
  };
  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setState({
      ...state,
      description: {
        ...state.description,
        defaultContent: value,
        defaultLang: langIsMain,
      },
    });
  };

  return (
    <Wrapper>
      <Note>
        <BreadCrumbsTitle
          labelIntl={["Store settings", "Plans"]}
          iconAlt="products"
        />
        <Link to="/plans-form">
          <ButtonCustomize
            variant="contained"
            color="secondary"
            onClick={handleSave}
            style={{ marginLeft: 10 }}
          >
            Save
          </ButtonCustomize>
        </Link>
      </Note>
      <div className="container">
        <div style={{ width: 800 }}>
          <Input
            name="name"
            label="Name"
            onChange={handleChangeName}
            value={state?.name.defaultContent}
            style={{ marginBottom: 10 }}
          />
          <Input
            name="description"
            label="Description"
            onChange={handleChangeDescription}
            multiline
            rows={3}
            style={{ marginBottom: 10 }}
          />
          <Input
            name="paymentFrequency"
            label="Payment Frequency"
            value={state.durationPack || ""}
            onChange={handleChangeSelect}
            type="number"
            style={{ marginBottom: 10 }}
          />
          <Box display="flex">
            <Input
              name="price"
              label="Price"
              value={state.price[0].price}
              onChange={handleChangePrice}
              onKeyPress={onKeyPressForFloat}
              style={{ minWidth: 590, marginRight: 10 }}
            />
            <Select
              name="currency"
              label="Currency"
              style={{ marginBottom: 10 }}
              value={state.price[0].currency}
              onChange={handleChangeCurrency}
            >
              {listCurencies?.getListCurrencies?.list?.map((currency) => (
                <MenuItem value={currency.code || ""} key={currency.id}>
                  {currency.code}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <FormControlLabel
            control={
              <Switch
                name="isExistLimit"
                onChange={(event) => setisExistLimit(event.target.checked)}
              />
            }
            label="Limit subscriptions product purchase"
            style={{ marginBottom: 5 }}
          />
          {isExistLimit && (
            <Input
              name="packLimitValue"
              label="Product quantity limit"
              value={state.productLimit || ""}
              type="number"
              onChange={handleChangeProductLimit}
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default PlansForm;
