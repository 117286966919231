import gql from "graphql-tag";

export const CREATE_PACK = gql`
  mutation CreatePack($input: CreatePackInput!) {
    createPack(input: $input) {
      id
    }
  }
`;
export const UPDATE_PACK = gql`
  mutation UpdatePack($input: UpdatePackInput!) {
    updatePack(input: $input) {
      id
    }
  }
`;

export const DELETE_PACK = gql`
  mutation DeletePack($input: DeletePackInput!) {
    deletePack(input: $input) {
      id
    }
  }
`;
export const GET_PACKS = gql`
  query GetListMenuPacks($input: GetListMenuPacksInput!) {
    getListMenuPacks(input: $input) {
      packs {
        name {
          defaultLang
          defaultContent
        }
        Id
        description {
          defaultLang
          defaultContent
        }
        productLimit
        durationPack
        price {
          currency
          price
        }
      }
    }
  }
`;
