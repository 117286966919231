import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useReactiveVar, useQuery, useMutation } from "@apollo/client";
import indexOf from "lodash/indexOf";
import moment from "moment";

import Table, { ColumnsProps } from "../../commons/Table";

import {
  BreadCrumbsTitle,
  ButtonCustomize,
  Modal,
  LoadingList,
} from "../../commons";
import { Search } from "../../components";

import {
  GET_LIST_MENU_TYPES,
  GET_MENU_TYPE_PRODUCTS_BOOKS,
  DELETE_CATEGORY_PRODUCTS,
  URL_THEME_PICTURES,
  HOST_RAWI,
} from "../../api";
import {
  GetListMenuTypesQuery,
  GetListMenuTypesQueryVariables,
  GetMenuTypeProductsQuery,
  GetMenuTypeProductsQueryVariables,
  DeleteCategoryProductMutation,
  DeleteCategoryProductMutationVariables,
  MenuPayload,
  MenuTypeProductPayload,
} from "../../api/types";
import { SelectedMenuVar, snackBarVar } from "../../api/local-state";

import { columnsCwallet, columnsRawi } from "../../utils";

import defaultPicture from "../../img/digishop/defaultPicture.jpg";

import { Wrapper, Note } from "./ListProductType--styles";

const { host } = window.location;

const ListProductType = (): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const history = useHistory();
  const intl = useIntl();
  const findIdentifier = host === HOST_RAWI ? "BOOKS" : "PRODUCTS";
  const [selectProducts, setSelectProducts] = React.useState<string[]>([]);
  const [name, setName] = React.useState<string | undefined>(undefined);
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);
  const [book, setBook] = React.useState<MenuTypeProductPayload>();

  const { data, loading: loadingMenu } = useQuery<
    GetListMenuTypesQuery,
    GetListMenuTypesQueryVariables
  >(GET_LIST_MENU_TYPES, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
      },
    },
    skip: !localMenu?.id,
  });

  const menuTypes = data?.getListMenuTypes?.list;

  const menuType =
    menuTypes?.find((o) => o.identifier === findIdentifier)?.id || "";

  const {
    data: listProducts,
    loading,
    refetch,
  } = useQuery<GetMenuTypeProductsQuery, GetMenuTypeProductsQueryVariables>(
    GET_MENU_TYPE_PRODUCTS_BOOKS,
    {
      variables: {
        input: {
          token,
          menuType,
          name,
          pageNumber: page,
          pageCount: rowsPerPage,
        },
      },
      skip: !menuType,
    }
  );

  const totalProducts = listProducts?.getMenuTypeProducts?.total || 0;

  const [DeleteProduct, { loading: loadingDelete }] = useMutation<
    DeleteCategoryProductMutation,
    DeleteCategoryProductMutationVariables
  >(DELETE_CATEGORY_PRODUCTS, {
    onCompleted: () => {
      refetch();
      setOpen(false);
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successful",
      });
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
  });

  const resteColunms = host === HOST_RAWI ? columnsRawi : columnsCwallet;

  const columns: ColumnsProps = [
    {
      header: (
        <Checkbox
          checked={totalProducts === selectProducts.length && totalProducts > 0}
          inputProps={{ "aria-label": "primary checkbox" }}
          onClick={(e) => {
            e.stopPropagation();
            handleAllSelectBooks();
          }}
        />
      ),
      accessor: "checkbox",
      cellProps: { align: "center", width: "30px" },
    },
    ...resteColunms,
  ];

  const renderTableRows = () => {
    return (
      listProducts?.getMenuTypeProducts?.list?.map((product) => ({
        id: product.id,
        checkbox: (
          <Checkbox
            checked={indexOf(selectProducts, product.id) !== -1}
            inputProps={{ "aria-label": "primary checkbox" }}
            onClick={(e) => {
              e.stopPropagation();
              product?.id && handleSelectBooks(product?.id);
            }}
          />
        ),
        image: product?.picture?.fileUrl ? (
          <img
            className="book__image"
            src={`${URL_THEME_PICTURES}${product.picture.fileUrl}`}
            alt="book_image"
          />
        ) : (
          <img className="book__image" src={defaultPicture} alt="book_image" />
        ),
        name: product?.name?.value,
        store: product?.attributes?.find((o) => o.identifier === "STORES")?.name
          ?.value,
        writer: product?.attributes?.find((o) => o.identifier === "WRITERS")
          ?.name?.value,
        reader: product?.attributes?.find((o) => o.identifier === "READERS")
          ?.name?.value,
        duration: moment(product.createdAt).format("MM/DD/YYYY"),
        categorie: product?.category?.name?.value,
        actions: (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
              setBook(product);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        ),
      })) || []
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleSelectBooks = (product: string) => {
    setSelectProducts((prevSelectProducts) => {
      const arraySelectProducts = [...prevSelectProducts];
      const position = indexOf(arraySelectProducts, product);
      if (position === -1) {
        arraySelectProducts.push(product);
      } else {
        arraySelectProducts.splice(position, 1);
      }
      return arraySelectProducts;
    });
  };

  const handleAllSelectBooks = () => {
    const arrayAllSelectBooks: string[] = [];
    if (selectProducts.length < totalProducts) {
      listProducts?.getMenuTypeProducts?.list?.map(
        (product) => product?.id && arrayAllSelectBooks.push(product.id)
      );
    }
    setSelectProducts(arrayAllSelectBooks);
  };

  return (
    <Wrapper>
      <Note>
        <BreadCrumbsTitle
          labelIntl={["Product Management", "Products"]}
          iconAlt="content"
        />

        <Link to="/add-product-type">
          <ButtonCustomize variant="contained" color="secondary">
            <AddIcon />
            &nbsp; Add new product
          </ButtonCustomize>
        </Link>
      </Note>
      {(loading || loadingMenu) && <LoadingList />}
      {listProducts && !loading && (
        <Box component={Paper} p={2} borderRadius={10}>
          <Search
            value={name}
            onSearch={(value) => setName(value)}
            style={{ marginBottom: 15 }}
          />
          <Box flex="1">
            <Table
              columns={columns}
              data={renderTableRows()}
              emptyMessage="No data available"
              tablePaginationProps={{
                labelDisplayedRows: ({ from, to }) => `${from} - ${to}`,
                count: totalProducts,
                page: page - 1,
                onPageChange: (_, newPage) => setPage(newPage + 1),
                rowsPerPage,
                onChangeRowsPerPage: handleChangeRowsPerPage,
              }}
            />
          </Box>
        </Box>
      )}
      <Menu
        anchorPosition={{
          left: anchorEl?.getBoundingClientRect().left || 0,
          top: anchorEl?.getBoundingClientRect().top || 0,
        }}
        anchorOrigin={{ horizontal: "left", vertical: "center" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorReference="anchorPosition"
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            history.push({
              pathname: `/edit-product-type/${book?.id}`,
              state: { item: book },
            });
            setAnchorEl(null);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpen(true);
            setAnchorEl(null);
          }}
          style={{ color: "#ff4949" }}
        >
          Delete
        </MenuItem>
      </Menu>
      <Modal
        open={open}
        title={intl.formatMessage({ id: "ProductForm.modalDeleteTitle" })}
        message={intl.formatMessage({
          id: "ProductForm.modalDeleteDescription",
        })}
        handleClose={() => setOpen(false)}
        handleContent={() =>
          book?.id && DeleteProduct({ variables: { token, id: book.id } })
        }
        action="delete"
        loading={loadingDelete}
      />
    </Wrapper>
  );
};

export default ListProductType;
