// Import libraries
import React, { FC, FormEvent, useState } from "react";
import { useIntl } from "react-intl";
import { useQuery, useReactiveVar } from "@apollo/client";
import {
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  TextField,
  MenuItem,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import find from "lodash/find";

import { BoxNew as Box, Input, Chip, Select } from "../../commons";
import { UploadPictureProduct } from "..";

import {
  HomePageV2CollectionInput,
  GetListTagsByMenuQuery,
  GetListTagsByMenuQueryVariables,
  GetListMenuCategoriesQuery,
  GetListMenuCategoriesQueryVariables,
  MenuPayload,
  LanguageCode,
  HomePageV2CollectionPayload,
  TitleInput,
} from "../../api/types";
import { GET_LIST_TAGS_BY_MENU, GETLISTMENUCATEGORIES } from "../../api";
import { SelectedMenuVar } from "../../api/local-state";

import { formValidation } from "./SettingsHomeCollectionForm--utils";
import { Wrapper } from "./SettingsHomeCollectionForm--styles";

export type Errors = {
  [Key in keyof HomePageV2CollectionInput]?: string;
};

type SettingsHomeCollectionFormProps = {
  open: boolean;
  collectionPayload?: HomePageV2CollectionPayload;
  onAddCollection: (collection: HomePageV2CollectionInput) => void;
  onClose: () => void;
  loading: boolean;
};

const SettingsHomeCollectionForm: FC<SettingsHomeCollectionFormProps> = ({
  open,
  collectionPayload,
  onClose,
  onAddCollection,
  loading,
}) => {
  const intl = useIntl();
  const token = localStorage.getItem("token") || "";
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const store = localMenu?.id || "";
  const languageIsMain = find(
    localMenu?.languages,
    (o) => o.isMain === true
  )?.language;

  const { data: listTagsData } = useQuery<
    GetListTagsByMenuQuery,
    GetListTagsByMenuQueryVariables
  >(GET_LIST_TAGS_BY_MENU, {
    skip: !store,
    variables: { input: { menu: store, token } },
  });

  const { data: listCategory } = useQuery<
    GetListMenuCategoriesQuery,
    GetListMenuCategoriesQueryVariables
  >(GETLISTMENUCATEGORIES, {
    variables: { input: { token, menu: localMenu?.id || "" } },
    skip: !localMenu?.id,
  });

  const [errors, setErrors] = useState<Errors>({});
  const [collection, setCollection] = useState<HomePageV2CollectionInput>({
    position: 0,
    titles: undefined,
    tags: [],
    picture: undefined,
    kind: undefined,
    object: undefined,
  });

  const handleOnSubmit = (event: FormEvent) => {
    event.preventDefault();
    const validationErrors = formValidation(collection);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      onAddCollection(collection);
    }
  };
  React.useEffect(() => {
    if (collectionPayload) {
      const arrayTitles: TitleInput[] = [];
      collectionPayload?.titles?.forEach((title) =>
        arrayTitles.push({
          id: title.id,
          languageCode: title.languageCode as LanguageCode,
          value: title.value,
        })
      );
      setCollection({
        picture: collectionPayload?.picture?.id,
        tags: collectionPayload?.tags,
        titles: arrayTitles,
        position: collectionPayload?.position,
      });
    }
  }, [collectionPayload]);

  return (
    <Wrapper open={open} onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h2">
            {collectionPayload ? "Update Collection" : "Create Collection"}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            form="collection-add-form"
            type="submit"
            disabled={loading}
          >
            {collectionPayload ? "Update" : "Add"}
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent className="dialog__content">
        <form id="collection-add-form" onSubmit={handleOnSubmit}>
          <Box flex={1} display="flex" flexDirection="column" pr={3}>
            <Input
              label="Collection Name"
              value={
                find(
                  collection.titles,
                  (o) => o.languageCode === languageIsMain?.code
                )?.value || ""
              }
              onChange={(e) =>
                setCollection({
                  ...collection,
                  titles: [
                    {
                      languageCode: languageIsMain?.code as LanguageCode,
                      value: e.target.value,
                    },
                  ],
                })
              }
              error={!!errors.titles}
              msgError={
                errors.titles && intl.formatMessage({ id: errors.titles })
              }
              style={{
                marginBottom: 10,
              }}
            />
            <div className="autocomplete">
              <Autocomplete
                multiple
                id="tags"
                options={listTagsData?.getListTagsByMenu?.tags || []}
                value={collection.tags || []}
                onChange={(_, tagsArray) => {
                  setCollection({ ...collection, tags: tagsArray });
                }}
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tags"
                    variant="filled"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </div>
            <Select
              label={intl.formatMessage({ id: "generalProduct.categoryList" })}
              // value={collection. || ""}
              onChange={(e) =>
                setCollection({
                  ...collection,
                  kind: "CATEGORY",
                  object: e.target.value as string,
                })
              }
            >
              {listCategory?.getListMenuCategories?.list
                ?.filter((obj) => obj.level === 1)
                .map((item) => (
                  <MenuItem value={item.id!} key={item.id!}>
                    {
                      item.names?.find(
                        (obj) => obj.languageCode === languageIsMain?.code
                      )?.value
                    }
                  </MenuItem>
                ))}
            </Select>
          </Box>
          <UploadPictureProduct
            description="Add image"
            uploadTo="collection"
            mode="Base"
            urlImage={{
              id: collectionPayload?.picture?.id,
              url: collectionPayload?.picture?.fileUrl,
            }}
            onLoaded={(pictureId) => {
              setCollection({ ...collection, picture: pictureId });
            }}
          />
        </form>
      </DialogContent>
    </Wrapper>
  );
};

export default SettingsHomeCollectionForm;
