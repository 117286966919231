import * as React from "react";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Switch from "@material-ui/core/Switch";
import { useReactiveVar, useQuery, useMutation } from "@apollo/client";
import findIndex from "lodash/findIndex";

import { BreadCrumbsTitle, ButtonCustomize, Input, Modal } from "../../commons";
import { GeneralInforForm, AudioContentForm } from "../../components";

import {
  GET_LIST_MENU_TYPES,
  CREATE_MENU_TYPE_PRODUCT,
  UPDATE_MENU_TYPE_PRODUCT,
  GET_MENU_TYPE_PRODUCTS_BOOKS,
  HOST_RAWI,
  HOST_CSHOP,
  GET_MENU_TYPE_PRODUCT_DETAILS,
  GET_LIST_PRODUCT_SPECS,
} from "../../api";
import {
  GetListMenuTypesQuery,
  GetListMenuTypesQueryVariables,
  CreateMenuTypeProductMutation,
  CreateMenuTypeProductMutationVariables,
  UpdateMenuTypeProductMutation,
  UpdateMenuTypeProductMutationVariables,
  MenuPayload,
  MenuTypeProductPayload,
  UpdateMenuTypeProductInput,
  KeyValuePayload,
  ProductGalleryInput,
  ProductKind,
  GetMenuTypeProductDetailsQuery,
  GetMenuTypeProductDetailsQueryVariables,
  GetListProductSpecsQuery,
  GetListProductSpecsQueryVariables,
} from "../../api/types";
import { SelectedMenuVar, snackBarVar } from "../../api/local-state";

import { Wrapper, Note } from "./ProductTypeForm--styles";
import {
  validation,
  ErrorsType,
  validationRawi,
  ErrorsTypeRawi,
} from "./ProductTypeForm--validation";

const { host } = window.location;

const ProductTypeForm = (): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const { id: idBook } = useParams<{
    id: string | undefined;
  }>();
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const history = useHistory();
  const location =
    useLocation<{ item?: MenuTypeProductPayload; categoryParent?: string }>();
  const path = location.pathname;
  const findIdentifier = host === HOST_RAWI ? "BOOKS" : "PRODUCTS";
  const bookPayload = location?.state?.item;
  const categoryParent = location?.state?.categoryParent;

  const [mode, setMode] = React.useState<"general" | "audio">("general");
  const [menuTypeProduct, setMenuTypeProduct] =
    React.useState<UpdateMenuTypeProductInput>({
      token,
      id: idBook || "",
      name: undefined,
      customFields: [],
      attributes: [],
      price: undefined,
      picture: undefined,
    });
  const [gallery, setGallery] = React.useState<ProductGalleryInput[]>([]);

  const [price, setPrice] = React.useState("");
  const [errors, setErrors] = React.useState<ErrorsType>();
  const [errorsRawi, setErrorsRawi] = React.useState<ErrorsTypeRawi>();
  const [disabledSave, setDisabledSave] = React.useState(false);
  const [isShowingWarning, setIsShowingWarning] = React.useState<boolean>(false);

  const dataBrief = useQuery<GetListProductSpecsQuery, GetListProductSpecsQueryVariables>(
    GET_LIST_PRODUCT_SPECS,
    {
      variables: {
        input: {
          token,
          product: idBook || "",
          identifier: "BRIEF",
        },
      },
      notifyOnNetworkStatusChange: true,
      skip: !idBook
    }
  );
  const dataChapters = useQuery<GetListProductSpecsQuery, GetListProductSpecsQueryVariables>(
    GET_LIST_PRODUCT_SPECS,
    {
      variables: {
        input: {
          token,
          product: idBook || "",
          identifier: "CHAPTERS",
        },
      },
      notifyOnNetworkStatusChange: true,
      skip: !idBook
    }
  );
  const isBriefExist = React.useMemo(() => dataBrief.data?.getListProductSpecs.list.length || 0, [dataBrief.data]);
  const isChaptersExist = React.useMemo(() => dataChapters.data?.getListProductSpecs.list.length || 0, [dataChapters.data]);

  const { data } = useQuery<
    GetListMenuTypesQuery,
    GetListMenuTypesQueryVariables
  >(GET_LIST_MENU_TYPES, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
      },
    },
    skip: !localMenu?.id,
  });

  const listType = data?.getListMenuTypes?.list;

  const idTypeBooks =
    listType?.find((o) => o.identifier === findIdentifier)?.id || "";

  // const { data: productDetails } = useQuery<
  //   GetMenuTypeProductDetailsQuery,
  //   GetMenuTypeProductDetailsQueryVariables
  // >(GET_MENU_TYPE_PRODUCT_DETAILS, {
  //   variables: {
  //     input: {
  //       token,
  //       productId: idBook || "",
  //     },
  //   },
  //   skip: !idBook,
  // });

  // console.log("#productDetails", productDetails);

  const titleForm = (): string => {
    switch (path) {
      case "/add-product-type":
        return "Add a product";
      default:
        return "Edit a product";
    }
  };

  React.useEffect(() => {
    if (bookPayload) {
      const arrayAttributes: string[] = [];
      const arrayCustomFields: KeyValuePayload[] = [];
      const arrayGallery: ProductGalleryInput[] = [];
      bookPayload?.attributes?.map(
        (item) => item?.id && arrayAttributes.push(item.id)
      );
      bookPayload?.customFields?.map((item) =>
        arrayCustomFields.push({
          key: item?.key,
          value: item?.value,
        })
      );
      bookPayload?.gallery?.map((item) =>
        arrayGallery.push({
          picture: item.id,
        })
      );
      setMenuTypeProduct({
        ...menuTypeProduct,
        picture: bookPayload?.picture?.id,
        name: {
          id: bookPayload?.name?.id,
          value: bookPayload?.name?.value,
        },
        attributes: arrayAttributes,
        customFields: arrayCustomFields,
        description: {
          id: bookPayload?.description?.id,
          value: bookPayload?.description?.value,
        },
        category: bookPayload?.category?.id,
        price: {
          currency: bookPayload?.price?.currency?.id,
          value: bookPayload?.price?.value,
        },
        tags: bookPayload?.tags,
        isPublished: bookPayload?.isPublished,
        isFree: bookPayload?.isFree,
        kind: bookPayload?.kind as ProductKind,
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      bookPayload?.price?.value && setPrice(bookPayload.price.value.toString());
    }
  }, [bookPayload]);

  React.useEffect(() => {
    if (!bookPayload) {
      setMenuTypeProduct({
        ...menuTypeProduct,
        category: categoryParent,
      });
    }
  }, [categoryParent]);

  const refetchQueries = [
    {
      query: GET_MENU_TYPE_PRODUCTS_BOOKS,
      variables: {
        input: {
          token,
          menuType: idTypeBooks,
          pageNumber: 1,
          pageCount: 10,
        },
      },
    },
  ];

  const [createProductType, { loading }] = useMutation<
    CreateMenuTypeProductMutation,
    CreateMenuTypeProductMutationVariables
  >(CREATE_MENU_TYPE_PRODUCT, {
    refetchQueries: () => refetchQueries,
    onCompleted: (productDetail) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      productDetail?.createMenuTypeProduct?.id &&
        history.push(
          `/edit-product-type/${productDetail.createMenuTypeProduct.id}`
        );
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successful",
      });
      setMode("audio");
      setDisabledSave(true);
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
  });

  const [updateProductType] = useMutation<
    UpdateMenuTypeProductMutation,
    UpdateMenuTypeProductMutationVariables
  >(UPDATE_MENU_TYPE_PRODUCT, {
    refetchQueries: () => refetchQueries,
    onCompleted: () => {
      history.push("/product-type");
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successful",
      });
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
  });

  const onKeyPressNumber = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^\d*\.?\d*$/;
    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  const handleDeleteGallery = (id: string) => {
    setGallery((prevGallery) => {
      const arrayGallery = [...prevGallery];
      const position = findIndex(arrayGallery, (o) => o.picture === id);
      arrayGallery?.splice(position, 1);
      return arrayGallery;
    });
  };

  const handleSubmit = () => {
    if (idBook) {
      updateProductType({
        variables: {
          input: {
            ...menuTypeProduct,
            id: idBook,
          },
        },
      });
    } else {
      createProductType({
        variables: {
          input: {
            token: menuTypeProduct.token,
            menuType: idTypeBooks,
            picture: menuTypeProduct?.picture,
            gallery,
            copiedPictures: menuTypeProduct?.copiedPictures,
            name: menuTypeProduct?.name?.value,
            attributes: menuTypeProduct?.attributes,
            customFields: menuTypeProduct?.customFields,
            description: menuTypeProduct?.description?.value
              ? menuTypeProduct.description.value
              : null,
            category: menuTypeProduct?.category,
            tags: menuTypeProduct?.tags,
            price: menuTypeProduct?.price,
            isPublished: menuTypeProduct?.isPublished,
            kind: menuTypeProduct?.kind,
            apiData: menuTypeProduct?.apiData,
          },
        },
      });
    }
  };

  const handleSave = () => {
    if (host === HOST_CSHOP) {
      const objectValidation = validation(menuTypeProduct);
      setErrors(objectValidation);
      if (Object.entries(objectValidation).length > 0) {
        if (
          !objectValidation?.name &&
          !objectValidation?.apiProvider &&
          objectValidation?.apiProviderID
        ) {
          snackBarVar({
            open: true,
            severity: "error",
            message: "You need assign your product",
          });
        }
      } else handleSubmit();
    } else {

      const objectValidation = validationRawi(menuTypeProduct);
      if (Object.entries(objectValidation).length > 0) {
        setErrorsRawi(objectValidation);
        if (objectValidation.picture) {
          snackBarVar({
            open: true,
            severity: "error",
            message: objectValidation.picture,
          });
        }
      } else if (!isBriefExist && !isChaptersExist && menuTypeProduct.isPublished) {
        setIsShowingWarning(true);
        setMenuTypeProduct({ ...menuTypeProduct, isPublished: false });
      } else {
        handleSubmit();
      }
    }
  };
  const onPressConfirm = () => {
    setIsShowingWarning(false);
    handleSubmit();
  }
  return (
    <Wrapper>
      <Note>
        <BreadCrumbsTitle
          labelIntl={["Products management", titleForm()]}
          iconAlt="content"
        />
        <Box display="flex">
          <ButtonCustomize>Discard</ButtonCustomize>

          <ButtonCustomize
            variant="contained"
            color="secondary"
            onClick={handleSave}
            disabled={loading || disabledSave}
            style={{ marginLeft: 10 }}
          >
            Save
          </ButtonCustomize>
        </Box>
      </Note>
      <Link to="/product-type">
        <ButtonCustomize className="arrow-back">
          <ArrowBackIcon /> Return
        </ButtonCustomize>
      </Link>
      <Box display="flex" marginTop={1}>
        {/* Box Form */}
        <Box
          flex="1"
          minWidth={815}
          component={Paper}
          borderRadius={10}
          marginRight={2}
        >
          <Box display="flex" className="tabs">
            <Typography
              variant="h3"
              className={mode === "general" ? "tab-selected" : "tab"}
              onClick={() => {
                setMode("general");
                setDisabledSave(false);
              }}
            >
              General info
            </Typography>
            {/* for RAWI */}
            {host === HOST_RAWI && (
              <Typography
                variant="h3"
                className={
                  mode === "audio"
                    ? "tab-selected"
                    : `tab ${!idBook && "disabled"}`
                }
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  idBook && setMode("audio");
                  setDisabledSave(true);
                }}
              >
                Audio content
              </Typography>
            )}
          </Box>

          {mode === "general" && (
            <GeneralInforForm
              idTypeBooks={idTypeBooks}
              menuTypeProduct={menuTypeProduct}
              picture={bookPayload?.picture}
              gallery={gallery}
              urlImageGallery={bookPayload?.gallery}
              onReturn={(palyoad) => {
                setMenuTypeProduct(palyoad);
              }}
              onReturnGallery={(items) => {
                setGallery(items);
              }}
              onDeleteGallery={(id) => {
                handleDeleteGallery(id);
              }}
              errorsForm={errors}
              errorsFormRawi={errorsRawi}
              onReturnErrors={(result) => setErrors(result)}
              onReturnErrorsRawi={(result) => setErrorsRawi(result)}
            />
          )}
          {mode === "audio" && <AudioContentForm />}
        </Box>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="column"
            component={Paper}
            borderRadius={10}
            p={2}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h2" className="title-pricing">
                Published
              </Typography>
              <Typography component="div">
                <Grid component="label" container alignItems="center">
                  <Grid item>Off</Grid>
                  <Grid item>
                    <Switch
                      checked={menuTypeProduct?.isPublished || false}
                      onChange={(e) => {
                        setMenuTypeProduct({
                          ...menuTypeProduct,
                          isPublished: e.target.checked,
                        });
                        setDisabledSave(false);
                      }}
                    />
                  </Grid>
                  <Grid item>On</Grid>
                </Grid>
              </Typography>
            </Box>
            {host === HOST_RAWI && (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h2" className="title-pricing">
                    Free
                  </Typography>
                  <Typography component="div">
                    <Grid component="label" container alignItems="center">
                      <Grid item>Off</Grid>
                      <Grid item>
                        <Switch
                          checked={menuTypeProduct?.isFree || false}
                          onChange={(e) => {
                            setMenuTypeProduct({
                              ...menuTypeProduct,
                              isFree: e.target.checked,
                            });
                            setDisabledSave(false);
                          }}
                        />
                      </Grid>
                      <Grid item>On</Grid>
                    </Grid>
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          {/* Box Pricing */}
          <Box
            width={330}
            component={Paper}
            borderRadius={10}
            marginTop={2}
            p={2}
          >
            <Typography
              variant="h2"
              className="title-pricing"
              style={{ marginBottom: 10 }}
            >
              Pricing
            </Typography>
            {host === HOST_RAWI && (
              <Typography
                variant="subtitle1"
                align="justify"
                className="pricing-description"
              >
                Set book buying price. this price will not be included when
                aquiring the product with subscription package. When price is 0
                it will be treated as free.
              </Typography>
            )}
            <Input
              label={`Base price (${localMenu?.currency?.code})`}
              value={price}
              onChange={(e) => {
                setPrice(e.target.value);
                setDisabledSave(false);
              }}
              onBlur={() => {
                setMenuTypeProduct({
                  ...menuTypeProduct,
                  price: {
                    currency: localMenu?.currency?.id || "",
                    value: parseFloat(price),
                  },
                });
              }}
              onKeyPress={onKeyPressNumber}
            />
            {menuTypeProduct?.apiData?.beamandgo?.unitPrice && (
              <Typography
                variant="subtitle2"
                style={{ fontWeight: 700, marginTop: 5, fontStyle: "italic" }}
              >
                Original price : {menuTypeProduct.apiData.beamandgo.unitPrice}
                &nbsp;
                {menuTypeProduct.apiData?.beamandgo?.unitPriceCurrencyCode}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Modal
        open={isShowingWarning}
        title="Warning"
        message="Book added successfuly but not published. Need to add a brief or a chapter to publish this boo"
        handleClose={onPressConfirm}
        handleContent={() => setIsShowingWarning(false)}
        action="notice"
      />
    </Wrapper>
  );
};

export default ProductTypeForm;
