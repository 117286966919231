import * as React from "react";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import PublishIcon from "@material-ui/icons/Publish";
import { useReactiveVar } from "@apollo/client";

import { ButtonCustomize } from "../commons";
import { UpdateAudio } from "../img/digishop/menu-icons";

import { URL_MEDIA } from "../api";
import { MenuPayload } from "../api/types";
import { SelectedMenuVar } from "../api/local-state";

type UploadFileProps = {
  uploadTo: "PDF_FILE" | "VIDEO_FILE" | "AUDIO_FILE";
  onLoaded?: (name: string, url: string) => void;
  fileName?: string;
  onUpdate?: (name: string, url: string) => void;
  onLoading?: (loading: boolean) => void;
};

const UploadFile = ({
  uploadTo,
  onLoaded,
  fileName,
  onUpdate,
  onLoading,
}: UploadFileProps): JSX.Element => {
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const inputFile = React.useRef<HTMLInputElement>(null);

  const [file, setFile] = React.useState<FileList | null>(null);
  const [progress, setProgress] = React.useState(0);

  const onButtonClick = () => {
    inputFile?.current?.click();
  };

  const getUrl = () => {
    switch (uploadTo) {
      case "AUDIO_FILE":
        return `${localMenu?.id}/AUDIO_FILE`;
      case "VIDEO_FILE":
        return `${localMenu?.id}/VIDEO_FILE`;
      case "PDF_FILE":
        return `${localMenu?.id}/PDF_FILE`;
      default:
        return `${localMenu?.id}/PDF_FILE`;
    }
  };

  const url = `${URL_MEDIA}/${getUrl()}`;

  const handleUpload = (fileArray: FileList | null) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onLoading && onLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    fileArray && setFile(fileArray);
    if (fileArray && fileArray[0] !== null && fileArray[0] !== undefined) {
      const dataForm = new FormData();
      dataForm.append("files", fileArray[0]);
      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener("progress", progressHandler, false);
      xhr.open("POST", url);
      xhr.send(dataForm);
      xhr.onload = async () => {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.response);
          if (fileName) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            onUpdate &&
              onUpdate(response.files[0].original_name, response.files[0].url);
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            onLoading && onLoading(false);
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            await (onLoaded &&
              onLoaded(response.files[0].original_name, response.files[0].url));
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            onLoading && onLoading(false);
          }
        }
      };
    }
  };

  const progressHandler = (event: ProgressEvent) => {
    setProgress((event.loaded / event.total) * 100);
  };

  React.useEffect(() => {
    setFile(null);
    if (inputFile.current?.value) inputFile.current.value = "";
  }, [fileName]);

  return (
    <Upload>
      {(file && file?.[0]) || fileName ? (
        <Box display="flex" alignItems="center">
          {progress < 100 && progress > 0 && (
            <CircularProgress
              variant="determinate"
              value={progress}
              size={20}
              style={{ marginRight: 5 }}
            />
          )}
          <Typography variant="subtitle2" className="file-url-upload">
            {file?.[0]?.name || fileName}
          </Typography>
          {(progress === 100 || fileName) && (
            <IconButton onClick={onButtonClick} style={{ marginLeft: 5 }}>
              <UpdateAudio />
            </IconButton>
          )}
        </Box>
      ) : (
        <ButtonCustomize
          variant="contained"
          onClick={onButtonClick}
          style={{ width: 140 }}
        >
          <PublishIcon /> &nbsp; Upload file
        </ButtonCustomize>
      )}

      <input
        ref={inputFile}
        accept="mp3"
        className="input-upload"
        multiple
        type="file"
        onChange={(e) => handleUpload(e.target.files)}
      />
    </Upload>
  );
};

export default UploadFile;

const Upload = styled.div`
  display: flex;
  .file-url-upload {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .input-upload {
    display: none;
  }
`;
