import React, { FC, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";
import { TextField, Typography, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import find from "lodash/find";
import findIndex from "lodash/findIndex";

import {
  UploadPictureProduct,
  SettingsHomeCollectionFormRawi,
  AssignSlideHomePageRawi,
} from "../../../components";
import {
  BreadCrumbsTitle,
  BoxNew as Box,
  Chip,
  Modal,
  ButtonCustomize,
  LoadingListHomePage,
} from "../../../commons";

import Table, { ColumnsProps } from "../../../commons/Table";

import { removeTypenameProp } from "../../../utils/typesUtils";

import { Wrapper } from "./SettingsHome--styles";

import { UPDATE_HOME_PAGE_CSHOP, GET_HOME_PAGE_CSHOP } from "../../../api";
import {
  GetHomePageV2Query,
  GetHomePageV2QueryVariables,
  UpdateHomePageCshopMutation,
  UpdateHomePageCshopMutationVariables,
  HomePageV2CollectionPayload,
  HomePageV2CollectionInput,
  HomePageV2SlideInput,
  MenuPayload,
  LanguageCode,
  TitleInput,
  PictureUrlPayload,
} from "../../../api/types";
import { snackBarVar, SelectedMenuVar } from "../../../api/local-state";
import { URL_PICTURES } from "../../../api/config";

type Slides = HomePageV2SlideInput[];

const HomeSettings: FC = () => {
  const token = localStorage.getItem("token") || "";

  const intl = useIntl();
  const [openCollection, setOpenCollection] = useState(false);
  const [openAssignSlide, setOpenAssignSlide] = useState(false);
  const [slidesFiles, setSlidesFiles] = useState<Slides>([]);
  const [collections, setCollections] = useState<HomePageV2CollectionPayload[]>(
    []
  );

  const [collectionToDelete, setCollectionToDelete] = useState<
    number | undefined
  >(undefined);
  const [listGallery, setListGallery] = useState<PictureUrlPayload[]>([]);

  const [pictureSlideSelected, setPictureSlideSelected] = useState("");
  const [collectionItem, setCollectionItem] =
    useState<HomePageV2CollectionPayload>();
  const [indexCollection, setIndexCollection] = useState<number | undefined>(
    undefined
  );
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const languageIsMain = find(
    localMenu?.languages,
    (o) => o.isMain === true
  )?.language;

  const { data: homePageData, loading } = useQuery<
    GetHomePageV2Query,
    GetHomePageV2QueryVariables
  >(GET_HOME_PAGE_CSHOP, {
    skip: !localMenu?.id,
    variables: { input: { menu: localMenu?.id || "", token } },
  });

  const [updateHome, { loading: loadingUpdate }] = useMutation<
    UpdateHomePageCshopMutation,
    UpdateHomePageCshopMutationVariables
  >(UPDATE_HOME_PAGE_CSHOP, {
    onCompleted: () => {
      snackBarVar({
        message: "Home Page was Updated Successfully",
        open: true,
        severity: "success",
      });
      if (openCollection) setOpenCollection(false);
      setCollectionItem(undefined);
    },
    refetchQueries: () => [
      {
        query: GET_HOME_PAGE_CSHOP,
        variables: {
          input: { menu: localMenu?.id || "", token },
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    setCollections(homePageData?.getHomePageV2?.collections || []);
    const arraySlides: Slides = [];
    homePageData?.getHomePageV2?.slides?.forEach((slide) => {
      if (slide.picture?.id) {
        arraySlides.push({
          picture: slide.picture.id,
          kind: slide.kind,
          object: slide.object,
        });
      }
    });
    setSlidesFiles(arraySlides);
    setListGallery(
      homePageData?.getHomePageV2?.slides?.map((slide) => ({
        id: slide.picture?.id || "",
        fileUrl: slide.picture?.fileUrl,
      })) || []
    );
  }, [homePageData]);

  const handleOnDeleteCollection = () => {
    setCollections(
      collections.filter((_, index) => index !== collectionToDelete)
    );
    setCollectionToDelete(undefined);
  };

  const handleOnChangePosition = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const collectionsArr = [...collections];
    const value = parseInt(event.target.value, 10);
    collectionsArr[index] = { ...collectionsArr[index], position: value };
    setCollections(collectionsArr);
  };
  const handleUpdateCollection = (collection?: HomePageV2CollectionInput) => {
    const collectionsInput: HomePageV2CollectionInput[] = collections.map(
      (item) => {
        const arrayTitle: TitleInput[] = [];

        item?.titles?.map((title) =>
          arrayTitle.push({
            id: title.id,
            languageCode: title.languageCode as LanguageCode,
            value: title.value,
          })
        );
        return removeTypenameProp({
          ...item,
          picture: item.picture?.id,
          titles: arrayTitle,
        });
      }
    );

    if (collection) {
      if (indexCollection !== undefined) {
        collectionsInput[indexCollection] = collection;
        setIndexCollection(undefined);
      } else {
        collectionsInput.push(collection);
      }
    }
    updateHome({
      variables: {
        input: {
          token,
          menu: localMenu?.id || "",
          slides: slidesFiles,
          collections: collectionsInput,
        },
      },
    });
  };

  const handleDeleteSlide = (pictureId: string) => {
    setSlidesFiles((prevSlides) =>
      prevSlides.filter((slideFile) => slideFile?.picture !== pictureId)
    );
  };

  const renderTableRows = () => {
    return (
      collections?.map((collection, index) => ({
        position: (
          <TextField
            className="collection__sort__input"
            type="number"
            variant="outlined"
            onChange={(e) => handleOnChangePosition(e, index)}
            value={collection.position}
            size="small"
          />
        ),
        name: find(
          collection.titles,
          (o) => o.languageCode === languageIsMain?.code
        )?.value,
        image: collection.picture?.fileUrl && (
          <img
            className="collection__image"
            src={`${URL_PICTURES}${collection.picture?.fileUrl}`}
            alt="some"
          />
        ),
        tags: collection?.tags?.map((tag) => (
          <Chip key={tag} size="small" label={tag} />
        )),
        actions: (
          <>
            <IconButton
              onClick={() => {
                setCollectionItem(collection);
                setOpenCollection(true);
                setIndexCollection(index);
              }}
              size="small"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              size="small"
              color="primary"
              style={{ color: "#ff4949", margin: "0 4px" }}
              onClick={() => setCollectionToDelete(index)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        ),
      })) || []
    );
  };

  const columns: ColumnsProps = [
    { header: "Position", accessor: "position", cellProps: { width: "80px" } },
    { header: "Image", accessor: "image", cellProps: { width: "130px" } },
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Tags",
      accessor: "tags",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "100px" },
    },
  ];

  return (
    <Wrapper>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width={1}
        mb={2}
      >
        <BreadCrumbsTitle
          labelIntl={["Store.settings", "Books Collections"]}
          iconAlt="Customers"
        />
        <ButtonCustomize
          variant="contained"
          disableElevation
          size="large"
          color="secondary"
          onClick={() => handleUpdateCollection()}
          disabled={loadingUpdate}
        >
          Save book collection
        </ButtonCustomize>
      </Box>
      {loading ? (
        <LoadingListHomePage />
      ) : (
        <>
          <Box paper p={2} mb={2}>
            <Typography variant="h2" style={{ marginBottom: 10 }}>
              Cover Book
            </Typography>
            <UploadPictureProduct
              description={intl.formatMessage({
                id: "generalProduct.galleryDescription",
              })}
              uploadTo="collection"
              mode="Gallery"
              urlImageGallery={listGallery}
              onLoaded={(picture) =>
                setSlidesFiles([...slidesFiles, { picture }])
              }
              onAssign={(id) => {
                setOpenAssignSlide(true);
                setPictureSlideSelected(id);
              }}
              onDeleted={handleDeleteSlide}
            />
          </Box>
          <Box paper width={1} p={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h2">Collections</Typography>
              <ButtonCustomize
                variant="contained"
                disableElevation
                size="medium"
                color="primary"
                onClick={() => setOpenCollection(true)}
              >
                Add Collection
              </ButtonCustomize>
            </Box>
            <Table
              columns={columns}
              data={renderTableRows()}
              emptyMessage="Add your first collection"
            />
          </Box>
        </>
      )}
      <AssignSlideHomePageRawi
        open={openAssignSlide}
        onClose={() => setOpenAssignSlide(false)}
        typeKind={
          find(slidesFiles, (o) => o.picture === pictureSlideSelected)?.kind
        }
        idObject={
          find(slidesFiles, (o) => o.picture === pictureSlideSelected)?.object
        }
        onReturn={(kind, id) => {
          setSlidesFiles((prevSlidesFiles) => {
            const arrayFiles = [...prevSlidesFiles];

            const position = findIndex(
              arrayFiles,
              (o) => o.picture === pictureSlideSelected
            );
            if (position !== -1) {
              arrayFiles[position] = {
                ...arrayFiles[position],
                kind,
                object: id,
              };
            }
            return arrayFiles;
          });
        }}
      />

      {openCollection && (
        <SettingsHomeCollectionFormRawi
          open={openCollection}
          onClose={() => {
            setOpenCollection(false);
            setCollectionItem(undefined);
          }}
          onAddCollection={handleUpdateCollection}
          loading={loadingUpdate}
          collectionPayload={collectionItem}
        />
      )}
      <Modal
        open={collectionToDelete !== undefined}
        title="Delete collection"
        message="Do you want to delete this collection"
        handleClose={() => setCollectionToDelete(undefined)}
        handleContent={() => handleOnDeleteCollection()}
        action="delete"
        loading={false}
      />
    </Wrapper>
  );
};

export default HomeSettings;
