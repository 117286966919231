import * as React from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AddIcon from "@material-ui/icons/Add";
import { useReactiveVar, useQuery, useMutation } from "@apollo/client";
import moment from "moment";

import { BreadCrumbsTitle, ButtonCustomize, Modal } from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";
import { Search, StoriesFormModal, UploadFile } from "../../components";

import {
  GET_LIST_MENU_TYPES,
  GET_MENU_TYPE_PRODUCTS_BOOKS,
  DELETE_CATEGORY_PRODUCTS,
  URL_THEME_PICTURES,
} from "../../api";

import {
  GetListMenuTypesQuery,
  GetListMenuTypesQueryVariables,
  GetMenuTypeProductsQuery,
  GetMenuTypeProductsQueryVariables,
  DeleteCategoryProductMutation,
  DeleteCategoryProductMutationVariables,
  MenuPayload,
  MenuTypeProductPayload,
} from "../../api/types";

import { SelectedMenuVar, snackBarVar } from "../../api/local-state";

import StaffManagement from "../../img/digishop/menu-icons/staffManagement.svg";
import defaultPicture from "../../img/digishop/defaultPicture.jpg";

import { Wrapper, Note } from "./Stories--styles";

const Stories = (): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const [name, setName] = React.useState<string | undefined>(undefined);
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(100);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);
  const [openFormModal, setOpenFormModal] = React.useState(false);
  const [titleFormModal, setTitleFormModal] = React.useState("");
  const [state, setState] = React.useState<MenuTypeProductPayload>();

  const { data } = useQuery<
    GetListMenuTypesQuery,
    GetListMenuTypesQueryVariables
  >(GET_LIST_MENU_TYPES, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
      },
    },
    skip: !localMenu?.id,
  });

  const menuTypes = data?.getListMenuTypes?.list;

  const menuType = menuTypes?.find((o) => o.identifier === "STORIES")?.id || "";

  const { data: listeStories, refetch } = useQuery<
    GetMenuTypeProductsQuery,
    GetMenuTypeProductsQueryVariables
  >(GET_MENU_TYPE_PRODUCTS_BOOKS, {
    variables: {
      input: {
        token,
        menuType,
        name,
        pageNumber: page,
        pageCount: rowsPerPage,
      },
    },
    skip: !menuType,
  });

  const totalStories = listeStories?.getMenuTypeProducts?.total || 0;

  const [DeleteProduct, { loading: loadingDelete }] = useMutation<
    DeleteCategoryProductMutation,
    DeleteCategoryProductMutationVariables
  >(DELETE_CATEGORY_PRODUCTS, {
    onCompleted: () => {
      refetch();
      setOpen(false);
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successful",
      });
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
  });

  const columns: ColumnsProps = [
    {
      header: <Checkbox inputProps={{ "aria-label": "primary checkbox" }} />,
      accessor: "checkbox",
      cellProps: { align: "center", width: "30px" },
    },
    {
      header: "Story picture",
      accessor: "picture",
    },
    {
      header: "Story duration",
      accessor: "duration",
    },
    {
      header: "Created on",
      accessor: "created",
    },
    {
      header: "Audio sample",
      accessor: "audio",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "80px" },
    },
  ];

  const renderTableRows = () => {
    return (
      listeStories?.getMenuTypeProducts?.list?.map((storie) => ({
        id: storie.id,
        checkbox: (
          <Checkbox inputProps={{ "aria-label": "primary checkbox" }} />
        ),
        picture: storie?.picture?.fileUrl ? (
          <img
            className="book__image"
            src={`${URL_THEME_PICTURES}${storie.picture.fileUrl}`}
            alt="book_image"
          />
        ) : (
          <img className="book__image" src={defaultPicture} alt="book_image" />
        ),
        duration: storie?.customFields?.find((o) => o.key === "DURATION")
          ?.value,
        created: moment(storie?.createdAt).format("MM/DD/YYYY HH:mm"),
        audio: (
          <UploadFile
            uploadTo="AUDIO_FILE"
            onLoaded={(kind, url) => {}}
            fileName={
              storie?.customFields?.find((o) => o.key === "AUDIO_FILE")
                ?.value || ""
            }
            onUpdate={(kind, url) => {}}
          />
        ),
        actions: (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
              setState(storie);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        ),
      })) || []
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <Wrapper>
      <Note>
        <BreadCrumbsTitle
          labelIntl={["Stories management", "Stories"]}
          icon={StaffManagement}
          iconAlt="content"
        />

        <ButtonCustomize
          variant="contained"
          color="secondary"
          onClick={() => {
            setOpenFormModal(true);
            setTitleFormModal("Add new story");
          }}
        >
          <AddIcon />
          &nbsp; Add new story
        </ButtonCustomize>
      </Note>
      <Box component={Paper} p={2} borderRadius={10}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h3" style={{ fontWeight: 700, marginLeft: 5 }}>
            Stories list
          </Typography>
          <FormControlLabel
            control={
              <Checkbox inputProps={{ "aria-label": "secondary checkbox" }} />
            }
            label="Display expired stories"
            style={{ marginRight: 0 }}
          />
        </Box>
        <Search
          value={name}
          onSearch={(value) => setName(value)}
          style={{ margin: "5px 0 15px 0" }}
        />
        <Box flex="1">
          <Table
            columns={columns}
            data={renderTableRows()}
            emptyMessage="No data available"
            tablePaginationProps={{
              labelDisplayedRows: ({ from, to }) => `${from} - ${to}`,
              count: totalStories,
              page: page - 1,
              onPageChange: (_, newPage) => setPage(newPage + 1),
              rowsPerPage,
              onChangeRowsPerPage: handleChangeRowsPerPage,
            }}
          />
        </Box>
      </Box>

      <Menu
        anchorPosition={{
          left: anchorEl?.getBoundingClientRect().left || 0,
          top: anchorEl?.getBoundingClientRect().top || 0,
        }}
        anchorOrigin={{ horizontal: "left", vertical: "center" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorReference="anchorPosition"
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setOpenFormModal(true);
            setTitleFormModal("Edit story");
            setAnchorEl(null);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpen(true);
            setAnchorEl(null);
          }}
          style={{ color: "#ff4949" }}
        >
          Delete
        </MenuItem>
      </Menu>

      <Modal
        open={open}
        title="Delete storie ?"
        message="Are you sure you want to remove this storie ?"
        handleClose={() => setOpen(false)}
        handleContent={() =>
          state?.id && DeleteProduct({ variables: { token, id: state.id } })
        }
        action="delete"
        loading={loadingDelete}
      />

      <StoriesFormModal
        idMenuType={menuType}
        open={openFormModal}
        title={titleFormModal}
        story={state}
        onClose={() => setOpenFormModal(false)}
        onRefetch={refetch}
      />
    </Wrapper>
  );
};

export default Stories;
