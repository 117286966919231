import gql from "graphql-tag";

// Query Get List Company Activities

export const GETLISTCOMPANYACTIVITIES = gql`
  query GetListCompanyActivities($languageCode: LanguageCode!) {
    getListCompanyActivities(input: { languageCode: $languageCode }) {
      list {
        id
        name
      }
    }
  }
`;

// Query Get My Account
export const GETMYACCOUNT = gql`
  query getMyAccount($token: String!, $languageCode: LanguageCode!) {
    getMyAccount(input: { token: $token, languageCode: $languageCode }) {
      account {
        id
        email
        accountActivation {
          activationStatus {
            id
            name
          }
          activationDate
        }
        informations {
          firstName
          lastName
          gender {
            id
            name
          }
        }
        contact {
          phone {
            countryCode
            number
          }
        }
        company {
          id
          domain
          shortName
          longName
          activity
          fiscal {
            corporateName
            matriculation
            taxCode
          }
          address {
            id
            roadNames {
              id
              languageCode
              value
            }
            postalCode
            country {
              id
              name
            }
            city {
              id
              name
            }
          }
          contacts {
            phone {
              countryCode
              number
            }
            email
          }
          website
          socials {
            kind {
              id
              name
            }
            value
          }
          logo {
            id
            quality
            kind
            fileName
          }
          characteristics {
            kind
            value
          }
        }
      }
    }
  }
`;

// query  Get My Account Settings

export const GETMYACCOUNTSETTINGS = gql`
  query GetMyAccountSettings($token: String!) {
    getMyAccountSettings(input: { token: $token }) {
      accountSettings {
        language
        currency
      }
    }
  }
`;

// query check Company Order Authorization

export const COMPANYORDERAUTHORIZATION = gql`
  query checkCompanyOrderAuthorization($token: String!) {
    checkCompanyOrderAuthorization(input: { token: $token }) {
      isAuthorized
    }
  }
`;

// mutation chnage password
export const CHANGEPASSWORD = gql`
  mutation ChangePassword(
    $token: String!
    $oldPassword: String!
    $newPassword: String!
  ) {
    changePassword(
      input: {
        token: $token
        oldPassword: $oldPassword
        newPassword: $newPassword
      }
    ) {
      success
    }
  }
`;

// query list social kind
export const GET_LIST_SOCIALS = gql`
  query GetListSocialKind($input: GetListSocialKindsInput!) {
    getListSocialKinds(input: $input) {
      list {
        id
        name
      }
    }
  }
`;

// mutation to update account
export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($input: UpdateMyAccountInput!) {
    updateMyAccount(input: $input) {
      success
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgetPassword($input: ForgetPasswordInput!) {
    forgetPassword(input: $input) {
      success
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      success
    }
  }
`;

export const GET_COMPANY = gql`
  query GetCompany($input: GetMyAccountInput!) {
    getMyAccount(input: $input) {
      account {
        id
        email
        company {
          id
          domain
        }
      }
    }
  }
`;

export const GETLISTSOCIALKINDS = gql`
  query GetListSocialKinds($input: GetListSocialKindsInput!) {
    getListSocialKinds(input: $input) {
      list {
        id
        name
      }
    }
  }
`;
